import React, { useEffect, useState } from 'react'
import { downloadFile, exportSvg, parseSvg } from '../utils'
import { findTemplate, getCatalogs, getFormats, updateTemplate, createTemplate, destroyTemplate, createProject } from '../api'
import Drawer from '../components/Drawer'
import Loader from '../components/UI/Loader'
import Controls from '../components/Controls'
import Menu from '../components/Menu'
import Button from '../components/UI/Button'
import { FiCheck, FiCopy, FiDownload, FiShare, FiTrash2 } from 'react-icons/fi'
import Auth from '../components/Auth'
import Label from '../components/UI/Label'
import { useThrottle } from '@react-hook/throttle'
import Error from '../components/UI/Error'
import '../i18n'
import { useTranslation } from 'react-i18next'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/template\/(\d+)/) || [])
const templateId = match[1]

function Template() {
  const { t } = useTranslation()
  const [template, setTemplate] = useState()
  const [local, setLocal] = useThrottle({ images: [], textes: [], background: {} }, 30, true)
  const [config, setConfig] = useState({})
  const [formats, setFormats] = useState()
  const [catalogs, setCatalogs] = useState()
  const [svgRef, setSvgRef] = useState()
  useEffect(() => {
    findTemplate(templateId).then(t => {
      setTemplate(t)
      if (t.svg) setLocal(parseSvg(t.svg))
    })
    getCatalogs().then(setCatalogs)
    getFormats().then(setFormats)
  }, [])
  useEffect(() => {
    if (template?.config?.svg)
      setConfig(parseSvg(template.config.svg))
  }, [template])
  const update = data => setLocal({ ...local, ...data })
  const save = async () => {
    // const render = await exportSvg(svgRef.current, 'webp')
    await updateTemplate(templateId, { svg: svgRef.current.outerHTML, name: template.name, catalog: template.catalog?.id, config: template.config.id })
  }
  const download = async () => {
    const render = await exportSvg(svgRef.current, 'webp')
    downloadFile(render, `template-${template.name}.webp`)
  }
  const downloadBase = async () => {
    const render = await exportSvg(template.config.svg)
    downloadFile(render, `export-${template.config.name}.png`, 'png')
  }
  const duplicate = async () => {
    // const render = await exportSvg(svgRef.current, 'webp')
    const created = await createTemplate({ svg: svgRef.current.outerHTML, name: `${template.name} Copie`, catalog: template.catalog?.id, config: template.config.id })
    window.location = `/template/${created.id}`
  }
  const destroy = async () => {
    await destroyTemplate(templateId)
    window.location = `/templates`
  }
  const createFrom = async () => {
    const project = await createProject({ svg: svgRef.current.outerHTML, config: template.config.id, catalog: template.catalog?.id })
    window.location = `/project/${project.id}_${project.token}`
  }
  if (template?.error) return <Error>{t('global.resourceNotFound')}</Error>
  if (template === undefined) return <Loader />

  return (
    <>
      <Menu title={t('global.models')} action={() => { window.location = '/templates'}}>
        <Label title={t('global.name')} className="mr-2 -mt-1">
          <input type="text" value={template.name} onChange={e => setTemplate({ ...template, name: e.target.value })} />
        </Label>
        <Label title="Catalogue" className="mr-2 -mt-1">
          <select value={template.catalog?.id} onChange={e => setTemplate({ ...template, catalog: catalogs.find(c => `${c.id}` === e.target.value) })}>
            <option value=""></option>
            {catalogs && !catalogs.error && catalogs.map(catalog =>
              <option key={catalog.id} value={catalog.id}>{catalog.name}</option>
            )}
          </select>
        </Label>
        <Label title="Format" className="mr-2 -mt-1">
          <select value={template.config.id} onChange={e => setTemplate({ ...template, config: formats.find(f => `${f.id}` === e.target.value) })}>
            {formats && !formats.error && formats.map(format => 
              <option key={format.id} value={format.id}>{format.name}</option>
            )}
          </select>
        </Label>
        <Button Icon={FiCheck} onClick={save} className="mx-1 mt-1">{t('global.save')}</Button>
        <Button Icon={FiShare} onClick={createFrom} variant="icon" className="mx-1 mt-1" label={t('template.useInProject')} />
        <Button Icon={FiDownload} onClick={download} variant="icon" className="mx-1 mt-1" label={t('global.download')} />
        <Button Icon={FiCopy} onClick={duplicate} variant="icon" className="mx-1 mt-1" label={t('global.duplicate')} />
        <Button Icon={FiTrash2} onClick={destroy} variant="icon" className="mx-1 mt-1" label={t('global.delete')} />
      </Menu>
      <div className="h-screen pt-12 lg:pt-16 flex flex-col lg:flex-row justify-between lg:justify-center w-full">
        <div className="flex justify-center items-center p-2 flex-shrink lg:p-10 max-h-3/4 lg:max-h-full lg:h-full">
          <Drawer config={config} local={local} setRef={ref => setSvgRef(ref)} className="rounded" />
        </div>
        <div className="overflow-x-scroll lg:overflow-x-auto lg:overflow-y-scroll w-screen lg:w-auto flex-shrink-0 flex flex-row lg:h-full lg:block">
          <Controls local={local} update={update} size={config.size} downloadBase={downloadBase} />
        </div>
      </div>
    </>
  )
}

export default function AuthTemplate() {
  return (
    <Auth>
      <Template />
    </Auth>
  )
}


